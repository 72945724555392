angular.module("aerosApp")
    .controller("OrgProfileCtrl",
    ["$scope", "aerosApi", "$stateParams", "userInvitationModal", "addRogueUnitModal", "addLicenseModal",
        "editOrgInfoModal", "showLicenseUsersModal", "chooseLicenseModal", "$uibModal", "Notification", "$translate",
        "allRoles", "PageService", "instructions", "routesConstant",
        function ($scope, aerosApi, $stateParams, userInvitationModal, addRogueUnitModal, addLicenseModal,
                  editOrgInfoModal, showLicenseUsersModal, chooseLicenseModal, $uibModal, Notification, $translate,
                  allRoles, PageService, instructions, routesConstant) {
            $scope.$watch("commonReady", function (newVal) {
                if (!newVal) return;
                if (!$scope.allow['viewProfile']) return $scope.reject();
                $scope.messages = {
                    "deleteSuccess": "User deleted.",
                    "deleteRogueUnitSuccess": "Rogue Unit deleted."
                };
                _.each($scope.messages, function (message, key) {
                    $translate(message).then(function (translation) {
                        $scope.messages[key] = translation;
                    });
                });
                $scope.roleNameById = {};
                $scope.roleRankById = {};
                $scope.roleById = {};
                $scope.rolesTooltip = '';

                (function setAllRoles() {
                    _.each(allRoles['orgRoles'], function (role) {
                        $scope.roleNameById[role.id] = role.name;
                        $scope.roleRankById[role.id] = role.rank;
                        $scope.rolesTooltip += "• " + role.name + ": " + role.description + " ";
                        $scope.roleById[role.id] = role;
                    });
                    $scope.bestRankedId = function (roles) {
                        var bestRank = 999;
                        var bestRankedId = "";
                        _.each(roles, function (role) {
                            var roleLower = role.role.toLowerCase();
                            var roleRank = $scope.roleRankById[role.role.toLowerCase()];
                            if (roleRank < bestRank) {
                                bestRank = roleRank;
                                bestRankedId = roleLower;
                            }
                        });
                        return bestRankedId;
                    };
                    var highestRank = _.minBy(_.filter(allRoles['orgRoles'], function (role) {
                        return _.includes($scope.roles, role.id);
                    }), function (roleObj) {
                        return roleObj.rank;
                    }).rank;
                    var editableRoleIds = _.map(_.filter(allRoles['orgRoles'], function (role) {
                        return role.rank >= highestRank;
                    }), "id");
                    $scope.canEdit = function (roleId) {
                        return _.includes(editableRoleIds, roleId);
                    };
                    $scope.availableRoles = _.filter(allRoles['orgRoles'], function (role) {
                        return _.includes(editableRoleIds, role.id);
                    });

                    $scope.basicRoles = _.filter(allRoles['orgRoles'], function (role) {
                        return role.id === 'user';
                    });

                    $scope.showRoleSelection = true;
                })();

                $scope.me = $scope.$parent.user;

                function loadOrganization() {
                    aerosApi.loadOrganization($scope.$parent.organization.id).then(function (rsp) {
                        console.log("my response: ", rsp);
                        var users = rsp.data.users;
                        var adminUsers = [];
                        var orgUsers = [];
                        $scope.org = rsp.data.info;
                        $scope.orgInfo = rsp.data.contacts.location;
                        $scope.orgContactInfo = rsp.data.contacts.contact;
                        $scope.licenses = rsp.data.licenses;
                        $scope.rogueUnits = rsp.data.rogueUnits;
                        $scope.availableLicenses = [];
                        $scope.availableLicensesForOrgUsers = [];
                        $scope.AllLicenses = [];
                        $scope.ActiveLicenses =[];
                        _.each(users, function (user) {
                            var result = $scope.licenses.filter(function (obj) {
                                return obj.id === user.licenseKey;
                            });

                            if (result.length) {
                                if (result[0].term === 'LifeTime') {
                                    user.expiration = "Lifetime";
                                } else {
                                    user.expiration = result[0].expirationDate;
                                }
                            }

                            user.searchIndex = user.firstName + " " + user.lastName + " " + user.email + " " + $scope.roleNameById[$scope.bestRankedId(user.roles.aerosRoles)] + " " + user.authStatus;
                            if (user.orgAdministrator || user.orgProjectManager) {
                                adminUsers.push(user);
                            } else {
                                orgUsers.push(user);
                            }
                        });

                        _.each($scope.licenses, function (license) {
                            if(license.assignedToAdmin!=null)
                               license.label = license.id + " (" + license.term + ")" +" ["+license.assignedToAdmin.firstName + " " + license.assignedToAdmin.lastName + "] ";
                            else
                              license.label = license.id + " (" + license.term + ")";
                            if (!license.hasOwnProperty("assignedToAdmin") &&(!(license.status==='EXPIRED'))) {
                                $scope.availableLicenses.push(license);
                            }
                            if ((license.openSeats > 0)&&(!(license.status==='EXPIRED'))) {
                                $scope.availableLicensesForOrgUsers.push(license);
                            }

                            if(!(license.status==="EXPIRED")){
                                $scope.ActiveLicenses.push(license);
                            }
                        });
                        $scope.adminUsers = adminUsers;
                        $scope.orgUsers = orgUsers;
                        $scope.AllLicenses= $scope.licenses;
                        $scope.licenses= $scope.ActiveLicenses;
                    });
                };

                $scope.contact = {
                    "Email": "aeros.support@aflglobal.com",
                    "Phone": "1-800-321-5298: press option 3"
                };
                $scope.showContact = function () {
                    $uibModal.open({
                        size: "md",
                        templateUrl: 'contactInfoModal.html',
                        resolve: {
                            contact: function () {
                                return $scope.contact;
                            },
                            organization: function () {
                                return $scope.organization;
                            }
                        },
                        controller: ["$scope", "$uibModalInstance", "contact", "organization", "Notification", function ($scope, $uibModalInstance, contact, organization, Notification) {
                            $scope.contact = contact;

                            $scope.sendEmail = function () {
                                aerosApi.sendEmail(organization.id)
                                    .success(function () {
                                        $uibModalInstance.close();
                                        Notification.success("Email was sent to aeros.support@aflglobal.com");
                                    })
                                    .error(function (err) {
                                        Notification.error("Email was not sent. " + err.message);
                                    });
                            };
                        }]
                    });
                };

                $scope.editOrgInfo = function (showOrgInfoDetails) {
                    var contactInfo = {};
                    if (showOrgInfoDetails) {
                        contactInfo = $scope.orgInfo;
                    } else {
                        contactInfo = $scope.orgContactInfo;
                    }
                    editOrgInfoModal.open($scope.org.id, contactInfo, showOrgInfoDetails).then(function () {
                        loadOrganization($scope.org.id);
                    });
                };

                $scope.addNewRogueUnit = function () {
                    addRogueUnitModal.open($scope.org).then(function () {
                        aerosApi.getRogueUnits($scope.org.id).then(function (rsp) {
                            $scope.rogueUnits = rsp.data.rogueUnits;
                        });
                    });
                };

                $scope.showLicenseUsers = function (license) {
                    showLicenseUsersModal.open(license, $scope.$parent.user).then(function () {
                        loadOrganization($scope.org.id);
                    });
                };

                $scope.addNewLicense = function () {
                    addLicenseModal.open($scope.org).then(function () {
                        loadOrganization($scope.org.id);
                    });
                };

                $scope.inviteNewAdminUser = function () {
                	var user; // not passing user to popup
                	var link; // not passing link to second popup
                    userInvitationModal.open('Invite a new Admin user to "' + $scope.org.name + '"',
			                    			false, // isEdit
			                    			$scope.availableLicenses,
			                    			$scope.org.id,
			                    			$scope.availableRoles,
			                    			$scope.availableRoles, // pre-select highest available (OA)
			                    			$scope.showRoleSelection,
			                    			false, // require one role
			                    			user,
			                    			link
                    ).then(function (user) {
                        aerosApi.inviteUser(user, user.licenseKey)
                            .success(function () {
                                Notification.success("User added to organization");
                                loadOrganization($scope.org.id);
                            })
                            .error(function (err) {
                                Notification.error("User not invited. " + err.message);
                            });
                    });
                };

                $scope.inviteTechUser = function () {
                    userInvitationModal.open(
                    						'Invite an AFL Tech Support user to "' + $scope.org.name + '"',
			                    			false,		// add, not edit
			                    			undefined,	// no license needed
			                    			$scope.org.id,
			                    			undefined,	// no available roles
			                    			undefined,	// no preselected roles,
			                    			false, 		// don't require a role
			                    			false,		// don't show role controls
			                    			undefined, 	// no user
			                    			undefined, 	// no link callback
			                    			true 		// only require email
                    ).then(function (user) {
                        aerosApi.inviteTechUser(user)
                            .success(function () {
                                Notification.success("User added to organization");
                                loadOrganization($scope.org.id);
                            })
                            .error(function (err) {
                                Notification.error("User not invited. " + err.message);
                            });
                    });
                };

                $scope.inviteNewOrgUser = function () {
                    userInvitationModal.open('Invite a new user to "' + $scope.org.name + '"', false, $scope.availableLicensesForOrgUsers, $scope.org.id, $scope.availableRoles, $scope.basicRoles, false).then(function (user) {
                        console.log("user: ", user, user.licenseKey);
                        aerosApi.inviteUser(user, user.licenseKey)
                            .success(function () {
                                Notification.success("User added to organization");
                                loadOrganization($scope.org.id);
                            })
                            .error(function (err) {
                                Notification.error("User not invited. " + err.message);
                            });
                    });
                };

                $scope.assignUser = function (user, admin) {
                    var availLicenses = [];
                    if (admin) {
                        availLicenses = $scope.availableLicenses;
                    } else {
                        availLicenses = $scope.availableLicensesForOrgUsers;
                    }
                    var userRoles = _.map(user.roles.aerosRoles, function (roleObj) {
                    	return $scope.roleById[roleObj.role];
                    });
                    chooseLicenseModal.open(availLicenses, $scope.availableRoles, userRoles, user).then(function (license) {
                        aerosApi.addUserToLicense($scope.org.id, license.id, user.username, user.roles[0])
                            .success(function () {
                                loadOrganization($scope.org.id);
                                Notification.success("User (" + user.username + ") successfully added to licensekey (" + license.id + ")");
                            })
                            .error(function (err) {
                                Notification.error("User not added to licensekey (" + license.id + "). " + err.message);
                            });
                    });
                };

                $scope.unAssignUser = function (user) {
                    aerosApi.removeUserFromLicense($scope.org.id, user.licenseKey, user.username)
                        .success(function () {
                            loadOrganization($scope.org.id);
                            Notification.success("User (" + user.username + ") successfully unassigned from licensekey (" + user.licenseKey + ")");
                        })
                        .error(function (err) {
                            Notification.error("User not unassigned. " + err.message);
                        });
                };

                $scope.deleteUser = function (user) {
                    if (!$scope.allow["deleteUsers"]) return;
                    $uibModal.open({
                        size: "md",
                        resolve: {
                            user: function () {
                                return user;
                            }
                        },
                        templateUrl: 'confirmDeleteModal.html',
                        controller: ["$scope", "user", function ($scope, user) {
                            $scope.fullName = user.firstName + " " + user.lastName;
                            $scope.title = "Delete User?";
                            $scope.message = "Are you sure you want to delete the user " + $scope.fullName + "?";
                        }]
                    }).result.then(function () {
                            return aerosApi.deleteUser(user.username, $scope.organization.id).then(function (data) {
                                loadOrganization($scope.organization.id);
                                Notification.success($scope.messages['deleteSuccess']);
                            });
                        });
                };

                $scope.deleteRogueUnit = function (rogueUnit) {
                    $uibModal.open({
                        size: "md",
                        resolve: {
                            rogueUnit: function () {
                                return rogueUnit;
                            }
                        },
                        templateUrl: 'confirmDeleteModal.html',
                        controller: ["$scope", "rogueUnit", function ($scope, rogueUnit) {
                            $scope.unitName = rogueUnit.serialNumber;
                            $scope.title = "Delete RogueUnit?";
                            $scope.message = "Are you sure you want to delete the Rogue Unit " + $scope.unitName + "?";
                        }]
                    }).result.then(function () {
                            return aerosApi.deleteRogueUnit($scope.organization.id, rogueUnit.serialNumber).then(function (data) {
                                loadOrganization($scope.organization.id);
                                Notification.success($scope.messages['deleteRogueUnitSuccess']);
                            });
                        });
                };

                $scope.userInvitation = function(username, orgId) {
                    aerosApi.userInvitationEmail(username, orgId).then(
                        function (response) {
                            Notification.success("A user activation email has been sent to " + username);
                        },
                        function (error) {
                            Notification.error("Error encountered sending user activation email to " + username);
                        })
                };

                $scope.userReset = function (username) {
                    aerosApi.sendResetEmail(username).then(
                        function (response) {
                            Notification.success("A reset-password email has been sent to " + username);
                        },
                        function (error) {
                            Notification.error("Error encountered sending reset-password email to " + username);
                        })
                };

                $scope.showActiveLicenses = function() {
                    $scope.licenses = $scope.ActiveLicenses;
                };

                $scope.showAllLicenses = function(){
                    $scope.licenses = $scope.AllLicenses;
                };
                PageService.setTitle('Organization Profile');
                loadOrganization();

                function findInstructionByAction(action) {
                    return instructions[routesConstant.CUSTOMER.ORGANIZATION_PROFILE.stateName]
                        .actions.find(function (element) {
                            return element.action && element.action.toLowerCase() === action.toLowerCase();
                        });
                }

                (function setActions() {
                    $scope.actions = {
                        "Add License": {
                            label: function (org) {
                                return findInstructionByAction('Add License').instructions;
                            },
                            icon: function (org) {
                                return 'fa-' + findInstructionByAction('Add License').icon;
                            },
                            if: function (org) {
                                return true;
                            },
                            disabled: function (org) {
                                return false;
                            },
                            action: function (org) {
                                $scope.addNewLicense();
                            },
                            placement: function(org) {
                                return 'left';
                            }
                        },
                        "Add Admin": {
                            label: function (org) {
                                return findInstructionByAction('Add License').instructions;
                            },
                            icon: function (org) {
                                return 'fa-' + findInstructionByAction('Add License').icon;
                            },
                            if: function (org) {
                                return true;
                            },
                            disabled: function (org) {
                                return !$scope.availableLicenses || $scope.availableLicenses.length === 0;
                            },
                            action: function (org) {
                                $scope.inviteNewAdminUser();
                            },
                            placement: function(org) {
                                return 'left';
                            }
                        },
                        "Invite Organization User": {
                            label: function (org) {
                                return findInstructionByAction('Add License').instructions;
                            },
                            icon: function (org) {
                                return 'fa-' + findInstructionByAction('Add License').icon;
                            },
                            if: function (org) {
                                return true;
                            },
                            disabled: function (org) {
                                return !$scope.availableLicensesForOrgUsers
                                    || $scope.availableLicensesForOrgUsers.length === 0;
                            },
                            action: function (org) {
                                $scope.inviteNewOrgUser();
                            },
                            placement: function(org) {
                                return 'left';
                            }
                        },
                        "Add Device Unit": {
                            label: function (org) {
                                return findInstructionByAction('Add License').instructions;
                            },
                            icon: function (org) {
                                return 'fa-' + findInstructionByAction('Add License').icon;
                            },
                            if: function (org) {
                                return true;
                            },
                            disabled: function (org) {
                                return false;
                            },
                            action: function (org) {
                                $scope.addNewRogueUnit();
                            },
                            placement: function(org) {
                                return 'left';
                            }
                        },
                        "Info": {
                            label: function(org) {
                                return findInstructionByAction('Info').instructions;
                            },
                            icon: function(org) {
                                return 'fa-' + findInstructionByAction('Info').icon;
                            }
                        }
                    };

                    $scope.adminUsersActions = [{
                        label: function (user) {
                            return findInstructionByAction('Reset Password').instructions;
                        },
                        icon: function (user) {
                            return 'fa-' + findInstructionByAction('Reset Password').icon;
                        },
                        if: function (user) {
                            return user.status === 'Active' && $scope.allow.activateUsers;
                        },
                        disabled: function (user) {
                            return false;
                        },
                        action: function (user) {
                            $scope.userReset(user.username);
                        }
                    }, {
                        label: function (user) {
                            return findInstructionByAction('Activation').instructions;
                        },
                        icon: function (user) {
                            return 'fa-' + findInstructionByAction('Activation').icon;
                        },
                        if: function (user) {
                            return $scope.allow.activateUsers && user.status !== 'BehalfOf';
                        },
                        disabled: function (user) {
                            return false;
                        },
                        action: function (user) {
                            $scope.userInvitation(user.username, $scope.org.id);
                        }
                    }, {
                        label: function (user) {
                            return findInstructionByAction('Assign').instructions;
                        },
                        icon: function (user) {
                            return 'fa-' + findInstructionByAction('Assign').icon;
                        },
                        if: function (user) {
                            return user.status !== 'BehalfOf';
                        },
                        disabled: function (user) {
                            return $scope.availableLicenses.length === 0;
                        },
                        action: function (user) {
                            $scope.assignUser(user, true);
                        }
                    }, {
                        label: function (user) {
                            return findInstructionByAction('Unassign').instructions;
                        },
                        icon: function (user) {
                            return 'fa-' + findInstructionByAction('Unassign').icon;
                        },
                        if: function (user) {
                            return user.status !== 'BehalfOf';
                        },
                        disabled: function (user) {
                            return user.username === $scope.me.username || user.status === 'Suspended';
                        },
                        action: function (user) {
                            $scope.unAssignUser(user);
                        }
                    }, {
                        label: function (user) {
                            return findInstructionByAction('Delete').instructions;
                        },
                        icon: function (user) {
                            return 'fa-' + findInstructionByAction('Delete').icon;
                        },
                        if: function (user) {
                            return true;
                        },
                        disabled: function (user) {
                            return user.username === $scope.me.username;
                        },
                        action: function (user) {
                            $scope.deleteUser(user);
                        }
                    }];

                    $scope.orgUsersActions = [{
                        label: function (user) {
                            return findInstructionByAction('Reset Password').instructions;
                        },
                        icon: function (user) {
                            return 'fa-' + findInstructionByAction('Reset Password').icon;
                        },
                        if: function (user) {
                            return user.status === 'Active' && $scope.allow.activateUsers;
                        },
                        disabled: function (user) {
                            return false;
                        },
                        action: function (user) {
                            $scope.userReset(user.username);
                        }
                    }, {
                        label: function (user) {
                            return findInstructionByAction('Activation').instructions;
                        },
                        icon: function (user) {
                            return 'fa-' + findInstructionByAction('Activation').icon;
                        },
                        if: function (user) {
                            return $scope.allow.activateUsers;
                        },
                        disabled: function (user) {
                            return false;
                        },
                        action: function (user) {
                            $scope.userInvitation(user.username, $scope.organization.id);
                        }
                    }, {
                        label: function (user) {
                            return findInstructionByAction('Assign').instructions;
                        },
                        icon: function (user) {
                            return 'fa-' + findInstructionByAction('Assign').icon;
                        },
                        if: function (user) {
                            return true;
                        },
                        disabled: function (user) {
                            return false;
                        },
                        action: function (user) {
                            $scope.assignUser(user, false);
                        }
                    }, {
                        label: function (user) {
                            return findInstructionByAction('Unassign').instructions;
                        },
                        icon: function (user) {
                            return 'fa-' + findInstructionByAction('Unassign').icon;
                        },
                        if: function (user) {
                            return true;
                        },
                        disabled: function (user) {
                            return user.status === 'Suspended';
                        },
                        action: function (user) {
                            $scope.unAssignUser(user);
                        }
                    }, {
                        label: function (user) {
                            return findInstructionByAction('Delete').instructions;
                        },
                        icon: function (user) {
                            return 'fa-' + findInstructionByAction('Delete').icon;
                        },
                        if: function (user) {
                            return true;
                        },
                        disabled: function (user) {
                            return false;
                        },
                        action: function (user) {
                            $scope.deleteUser(user);
                        }
                    }];
                })();
            });
        }]);
